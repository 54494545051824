import React from "react"
import VideoJS from "../../Hooks/videojs/videojs"

const VideoPlayer = ({ source }) => {
  const playerRef = React.useRef(null)

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    fill: true,
    fluid: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    sources: [
      {
        src: source,
        type: "video/mp4",
      },
    ],
  }

  const handlePlayerReady = player => {
    playerRef.current = player

    player.on("waiting", () => {
      player.log("player is waiting")
    })

    player.on("dispose", () => {
      player.log("player will dispose")
    })
  }

  return (
    <>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  )
}

export default VideoPlayer
